/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/

/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";
@import "../milligram/color";

/* general
----------------------------------------------------------------------------------------------*/


/* header
----------------------------------------------------------------------------------------------*/
header {
    background: $color-secondary; position: fixed; left: 0; right: 0; top: 0; z-index: 10;
    .wrapper {
        display: flex; justify-content: space-between; align-items: center; position: relative;
    }
    .top-header {
       
        .wrapper {
            height: 60px;
        }
        .ico {width: 24px; height: 24px; display: block;}
        .logo-center {
            width: 160px; height: 60px; position: absolute; left: 0; right: 0; margin: auto;
            img {display: block;}
        }
        .ico-search {
            background: url('../images/material/icon-search.svg') no-repeat center; cursor: pointer; position: relative;
        }
        .right-top-header {
            display: flex; justify-content: flex-end;
            .overlay-u {
                position: fixed; top: 0; left: 0; right: 0; bottom: 0;background: rgba(255,255,255,.5); width: 100%;
                height: 100%;
            }
            .user-header {
                .ico-user {
                   background: url('../images/material/icon-user.svg') no-repeat center right;
                   padding-right: 30px;
                   width: auto;
                   span {
                       color: $color-initial;
                       max-width: 90px;
                       overflow: hidden;
                       white-space: nowrap;
                       text-overflow: ellipsis;
                       display: block;
                       line-height: 24px;
                       font-size: .875rem;
                   }
                }
                .not-login {
                    background: url('../images/material/icon-user.svg') no-repeat center;
                }
                .drop-user-wrap{
                    position: fixed;  right: 56px; z-index: 10; top: 0; bottom: 0; min-height: 100vh; overflow: auto; left: auto;
                    display: none;
                    
                    .user-inner {
                        background: $color-initial; width: 360px; padding: 32px; position: relative;z-index: 3;
                        .button {width: 100%;}
                        ul {
                            li {
                                a {
                                    color: $color-grey;
                                    &:hover {color: $color-secondary;}
                                }
                            }
                        }
                    }
                }
            }
            .ico-wishlist {
                margin: 0 30px;
                background: url('../images/material/icon-wishlist.svg') no-repeat center;
            }
            .cart-header {
                .ico-cart {
                   background: url('../images/material/icon-cart.svg') no-repeat center; position: relative;
                   span {
                       width: 18px; height: 18px; background: $color-primary;color: $color-initial;
                       text-align: center; line-height: 18px; font-size: .65rem; border-radius: 50%;
                       position: absolute; right: -6px; bottom: -6px; display: none;
                   }
                   &.act {
                       span {display: block;}
                   }
                   
                }
                ::-webkit-scrollbar {width: 4px;}
                ::-webkit-scrollbar-track {background: #f1f1f1;width: 4px;}
                ::-webkit-scrollbar-thumb {background: $color-grey;width: 4px;}
                ::-webkit-scrollbar-thumb:hover {background: $color-grey;}
                .drop-cart-wrap {
                    position: fixed;  right: 56px; z-index: 10; top: 0; bottom: 0; min-height: 100vh; overflow: auto; left: auto;
                    display: none;
                    .cart-inner {
                        background: $color-initial; width: 405px; padding: 32px; position: relative;z-index: 3;
                        >h4 {font-size: 1.5rem; font-weight: 300;}
                        .empty {
                            text-align: center;
                            > h4 {font-size: 1.5rem; font-weight: 300;}
                            div {
                                font-weight: 300;
                                strong {display: block;}
                            }
                        }
                        .bottom-cart {
                            .button {display: block; margin-bottom: 10px;}
                            .note-product-list {
                                p {font-size: .625rem; color: $color-grey; line-height: 1.4;}
                            }
                            .dl {
                                display: flex; justify-content: space-between; padding-top: 10px; border-top: 1px solid #C4C4C4; margin-bottom: 10px;
                                label,span {font-size: 1.25rem; font-weight: 700; display: block;}
                            }
                        }
                    }
                    .list-cart {
                        .box {
                            padding: 15px; display: flex; padding-right: 0;
                            figure {width: 75px;}
                            figcaption {
                                width: calc(100% - 75px); padding-left: 25px; padding-right: 30px; position: relative;
                                .remove {position: absolute; bottom: 0; right: 0;z-index: 3;}
                                h5 {font-size: 1.25;margin: 0 0 5px; font-weight: 700;}
                                p {font-size: .75rem;margin-bottom: 6px;}
                                .price {
                                    font-size: 1.25rem; font-weight: 700;
                                    span {display: inline-block;font-size: .75rem; font-weight: 300;}
                                }
                                .stock {color: $color-primary; font-weight: 700;font-size: 1rem;}
                            }
                            &.out-stock {
                                figure{
                                    opacity: 0.4;
                                }
                                figcaption {
                                    * {
                                        opacity: 0.4;
                                    }
                                    .remove {
                                        opacity: 1;
                                        img {opacity: 1;}
                                    }
                                    .stock {opacity: 1;}
                                }
                            }
                        }
                        
                    }
                }
            }
            .burger-ico {
                width: 18px; height: 13px; position: absolute; right: 0; top: 0; bottom: 0; margin: auto; display: none;
                span {
                    height: 2.4px; width: 100%; top: 0; bottom: auto; position: absolute; left: auto; right: 0;
                    background: $color-initial; border-radius: 2px; transition: all ease-out 0.3s;
                    &:nth-child(1){top: 0;}
                    &:nth-child(2){top: 5.3px;}
                    &:nth-child(3){top: 10.6px;}
                }
                &.act {
                    span {
                        transition: all ease-out 0.3s;
                        &:nth-child(1){top: 5px; transform: rotate(45deg)}
                        &:nth-child(2){width: 0;}
                        &:nth-child(3){top: 5px;transform: rotate(-45deg)}
                    }
                }
            }
        }
    }
    .main-header {
        transition: all ease-out 0.35s;
        .mobile-icon-menu {
            display:none; padding-top: 24px; margin-top: 20px; border-top: 1px solid rgba(255,255,255,.5);
            a {
                font-size: .875rem; color: $color-initial;
                padding-left: 30px; text-transform: uppercase; display: block;
                margin-bottom: 12px; background-size: 22px !important;
                position: relative;
                &.profile-mobile {
                    background: url('../images/material/icon-user.svg') no-repeat left center;
                }
                &.wishlist-mobile {
                    background: url('../images/material/icon-wishlist.svg') no-repeat left center;
                }
                &.cart-mobile {
                    background: url('../images/material/icon-cart.svg') no-repeat left center;
                    small {
                        width: 16px;
                        height: 16px;
                        background: #860014;
                        color: #fff;
                        text-align: center;
                        line-height: 16px;
                        font-size: 0.5rem;
                        border-radius: 50%;
                        position: absolute;
                        right: auto;
                        left: 12px;
                        bottom: -6px;
                    }
                }
            }
        }
        nav {
            ul {
                display: flex; justify-content: flex-start; margin: 0; list-style: none;
                li {
                    margin: 0 28px;
                    a {color: $color-initial; display: block; padding: 20px 0px; line-height: 1.3; font-size: .875rem;}
                    &:first-child {
                        margin-left: 0;
                    }
                    .drop-hover-menu {
                        position: absolute; background: $color-secondary; padding: 60px 100px; display: none; transition: all ease-out 0.4s;
                        left: 0;
                        .wrap-menu-sub {
                            display: flex; margin-left: -80px;
                        }
                        .col {
                            width: 33%; min-width: 410px; padding: 0 80px;transition: all ease-out 0.3s;
                            ul {
                                display: block;
                                li {
                                    margin: 0;
                                    a {
                                        padding: 10px 0; font-size: 1.25rem; font-weight: 300; position: relative;
                                        &:hover,&.active {
                                            &:before {
                                                transition: all ease-out 0.25s; width: 30px;
                                            }
                                        }
                                        &:before {
                                            content: ''; height: 1px; position: absolute;left: 0; bottom: 3px; background: $color-primary;
                                            width: 0; display: block;margin: auto; top: auto; right: auto; transition: all ease-out 0.25s;
                                        }

                                    }
                                    &.sub-2 {
                                        ul {
                                            bottom: 0;
                                            position: absolute; top: 0; left: 50%; top: 0; min-width: 410px;
                                            display: none;
                                            // padding-left: 80px; border-left: 1px solid #868686; 
                                            li {
                                              padding-left: 80px;
                                              border-left: 1px solid #fff;
                                            }
                                        }
                                        &:hover {
                                            > ul {display: block;}
                                        }
                                    }
                                    &.act-sub-drop {
                                        > a {
                                            &:before {width: 30px;}
                                        }
                                    }
                                    &.back-to {
                                        display: none;
                                    }
                                }
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                            .box-sub-menu {
                                width: 330px; margin-bottom: 30px;
                                a {
                                    display: block; position: relative; padding: 0;
                                    p {color: $color-initial; padding: 0 45px 24px; position: absolute; bottom: 0;left: 0; right: 0; margin: 0;}
                                    img {display: block; width: 100%;}
                                }
                            }
                            &.border {
                                border-left: 1px solid #868686;
                            }
                            &.hov-sub {
                                min-width: 854px; padding-right: 420px; position: relative;transition: all ease-out 0.3s;
                            }
                        }
                        
                    }
                }
            }
            > ul {
                > li {
                    > a {
                        position: relative; font-weight: 500;
                        &:before {
                            content: ''; height: 3px; position: absolute;left: auto; bottom: 0; background: $color-primary;
                            width: 0; display: block;margin: auto; top: auto; right: 0; transition: all ease-out 0.25s;
                        }
                    }
                    &:hover,&.act,&.active {
                        > a {
                            &:before {
                                width: 100%; left: auto; right: auto;transition: all ease-out 0.25s;
                            }
                        }
                        .drop-hover-menu {
                            transition: all ease-out 0.4s; display: block;
                        }
                    }
                }
            }
            &.nav-right {
                ul {
                    li{
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    &.float {
        background: rgba(31,31,31,.5);
        .main-header {
            opacity: 0;
            margin-top: -58px;
            visibility: hidden;
            transition: all ease-out 0.35s;
        }
        &.view-menu {
            background: rgba(31,31,31,1);
            .main-header {
                opacity: 1;
                margin-top: 0;
                visibility: visible;
                transition: all ease-out 0.35s;
            }
        }
    }
    
}


.style-src {
    form {
        label {font-weight: 400; color: $color-grey;}
        .input {
            position: relative;
            input[type="text"] {
                border-radius: 0; border: none; border-bottom: 1px solid rgba(134, 134, 134, .6);
                padding-right: 50px; font-weight: 700; padding-left: 0;
            }
            input[type="submit"] {
                position: absolute; font-size: 0; width: 40px; height: 100%; position: absolute;
                top: 0; bottom: 0; right: 0; border: none; cursor: pointer; padding: 0;
                background: url('../images/material/icon-search-2.svg') no-repeat center;
            }
        }
    }
}
.wrap-search-fix {
    position: fixed; top: 0;bottom: 0; left: 0; right: 0; background: $color-initial; z-index: 11;
    height: 100vh; padding: 30px; display: none;
    .wrapper {
        padding: 0 96px;
    }
    .close-src {
        width: 36px; height: 36px; position: absolute; top: 30px; right: 30px; cursor: pointer;transition: all ease-out 0.25s;
        &:before,&:after {
            content: ''; height: 2px; width: 24px; background: $color-secondary; position: absolute;
            top: 0; bottom: 0; left: 0; right: 0; margin: auto;transform: rotate(45deg); transition: all ease-out 0.25s;
        }
        &:after {
            transform: rotate(-45deg);
        }
        &:hover {
            transition: all ease-out 0.25s; transform: rotate(90deg);
            &:before,&::after {
                transition: all ease-out 0.25s; background: $color-primary;
            }
        }
    }
    .recent {
        max-width: 400px;
        display: block; margin: 50px auto;
        text-align: center;
        ul {
            li {
                a {
                    display: block; color: $color-secondary; font-weight: 300; text-decoration: underline;
                    &:hover {
                        color: $color-primary;
                    }
                }
            }
        }

    }
    .result-product {
        display: flex; max-height: calc(100vh - 180px); overflow: auto; max-height: calc((var(--vh, 1vh) * 100) - 180px);
        .col-result {
            width: 25%; padding-right: 30px;
            &:first-child {
                width: 50%;
            }
            small {display: block; font-size: 1rem;}
            h4 {
                font-size: 1.25rem; font-weight: 700; margin-bottom: 30px;
            }
            .ls-result {
                a {
                    display: flex; color: $color-secondary;margin-bottom: 20px;
                    figure {
                        width: 100px; padding: 15px 24px;background: linear-gradient(180deg, #FFFFFF 58.85%, #F2F2F2 64.58%, #F5F5F5 87.5%);
                    }
                    figcaption {
                        padding-left: 16px;
                        h5 {margin-bottom: 0px; font-weight: 700;}
                    }
                }
            }
        }
    }
}


/* footer
----------------------------------------------------------------------------------------------*/
footer {
    background: $color-secondary; color: $color-initial; position: relative; overflow: hidden; padding-top: 62px;
    h5 {font-weight: 700; margin-bottom: 15px; color: $color-initial;}
    a {
        color: $color-initial;
        &:hover {
            opacity: .7;color: $color-initial;
        }
    }
    .nav-footer {
        .list-nav-footer {
            display: flex; justify-content: flex-start; padding-bottom: 62px;
            width: calc(100% + 80px); margin-left: -40px;
            .col {
                padding: 0 40px;
                width: 25%;
            }
            ul {
                margin: 0;
            }
            
        }
    }
    .middle-footer {
        padding: 32px 0 40px; border-top: 1px solid rgba(255,255,255,0.4);border-bottom: 1px solid rgba(255,255,255,0.4);
        position: relative;
        .list-location-footer {
            display: flex; padding-right: 250px; position: relative;
            .col {
                width: 270px; padding-right: 100px; box-sizing: content-box;
                &:last-child {padding-right: 0;}
                p {margin: 0 0 10px;}
                .find {
                  color: $color-grey; font-weight: 500; 
                  display: inline-block; padding-left: 28px;
                  background: url('../images/material/icon-location.svg') no-repeat left center;
                }
            }
            
        }
        .map-footer {
            position: absolute; width: 250px; right: 0; height: 170px; top: 0; bottom: 0; margin: auto;
            iframe {height: 100%;}
        }
    }
    .bottom-footer {
        padding: 62px 0; display: flex; justify-content: space-between;
        .col-bottom {
            display: flex; align-items: flex-end;
            h5 {margin: 0; margin-right: 30px;}
            .sosmed-footer {
                display: flex; margin: 0; align-items: flex-end;
                li {
                    margin: 0 10px;
                    a {
                        display: block;
                        img {display: block;}
                    }
                }
            }
            .contact-footer {
                display: flex;
                .contact-no {
                    margin-right: 20px;
                    a {display: block;}
                }
            }
            form {
                margin: 0; position: relative; width: calc(100% - 120px);
                input[type="text"] {
                    border: none; border-bottom: 1px solid rgba(255,255,255,0.4); border-radius: 0;
                    padding: 13px 50px 13px 0; color: $color-initial; height: auto; margin: 0; width: 100%;
                }
                input[type="submit"] {
                    padding: 0; width: 40px; height: 24px; background: url('../images/material/icon-slide-arrow.svg') no-repeat;
                    transform: rotate(180deg); border: none; background-size: 20px; font-size: 0;
                    position: absolute; margin: auto; top: 0; bottom: 0; right: 0;
                }
            }
            &.newsletter {
                width: 524px;
            }
        }
    }
    .nav-bottom {
        background: $color-initial; padding: 20px 0; display: flex; justify-content: space-between; align-items: center;
        position: relative;
        &:before {
            content: ''; width: auto; left: calc((-100vw + 1440px - 112px) / 2);right: calc((-100vw + 1440px - 112px) / 2);; position: absolute;
            background: $color-initial; top: 0; bottom: 0; height: 100%; display: block; z-index: 1;
        }
        ul {
            margin: 0; display: flex; position: relative;z-index: 2;
            li {
                margin: 0 30px 0 0;
                a {color: $color-grey; text-transform: uppercase; font-size: .75rem; display: block;}
                &:last-child {margin-right: 0}
            }
        }
        .ico-visa {
            display: flex; justify-content: flex-end; align-items: center;position: relative;z-index: 2;
            a {
                display: block; margin-left: 15px;
                img {display: block;}
            }
        }
    }

}

@media all and (max-width: 1439px) {






    footer .nav-bottom:before {
        left: -56px; right: -56px;
    }
    

}

@media (max-width: 1366px) {
  header .main-header nav ul li .drop-hover-menu {
    padding: 40px 80px;
    .col ul li a {
      font-size: 1.125rem;
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {

header {
    .top-header {
        .wrapper {height: 60px;}
        .logo-center {
            width: 160px; height: auto;
        }
        .right-top-header {
            .cart-header {
                .drop-cart-wrap {
                    right: 40px;
                }
            }
            .user-header {
                .drop-user-wrap {
                    right: 40px;
                }
            }
        }
    }
    .main-header {
        nav {
            ul {
                li {
                    margin: 0 20px;
                    &.have-sub {
                        &:hover {
                            .drop-hover-menu {display: none;}
                        }
                        &.act {
                            .drop-hover-menu {
                                display: block;
                            }
                        }
                    }
                    .drop-hover-menu {
                        padding: 40px 40px; right: 0;
                        .wrap-menu-sub {
                            margin-left: 0;
                        }
                        .col {
                            min-width: 33.33%; padding: 0 40px;
                            ul {
                                li {
                                    a {font-size: 1rem;}
                                    &.sub-2 {
                                        ul {
                                            border-left: 1px solid #868686; 
                                            padding-left: 40px; min-width: 33.333%; left: 33.33%; top: 40px;
                                            li {
                                              border: 0;
                                              padding-left: 0;
                                            }
                                        }
                                        &:hover {
                                            ul {
                                                display: none; 
                                            }
                                        }
                                        &.act-sub-drop {
                                            ul {display: block; max-height: 320px; overflow: auto;}
                                        }
                                    }
                                }
                            }
                            &:first-child {
                                padding-left: 0;
                            }
                            .box-sub-menu {
                                width: 100%;
                                a {
                                    p {padding: 0 24px 20px;}
                                }
                            }
                            &.left-act {
                                padding-left: calc(33.33% + 40px); width: 66.66%;
                            }
                        }
                    }
                }
            }
        }
    }
}



footer {
    padding-top: 40px;
    .nav-footer {
        .list-nav-footer {
            padding-bottom: 40px;
            // .col {width: 200px; min-width: 180px;}
            a {font-weight: 300;}
        }
    }
    .middle-footer {
        .list-location-footer {
            flex-wrap: wrap;
            .col {width: 255px; padding-right: 50px;}
        }
    }
    .bottom-footer {
        padding: 40px 0;
        .col-bottom {
            display: block;
            &:nth-child(1){
                display: flex;
                h5 {font-size: 1.125rem; font-weight: 700; margin-right: 30px;}
            }
            &.newsletter {
                width: 320px;
            }
            .sosmed-footer {
                margin-top: 6px;
                li {margin: 0 12px 0 0;}
            }
            form {
                width: 100%;
                input[type="text"] {padding: 6px 40px 6px 0; font-size: .75rem;}
            }
            h5 {font-size: .75rem; font-weight: 400;margin-right: 0;}
            small {display: block;margin-bottom: 4px;}
        }
    }
}

.wrap-search-fix {
    padding-top: 50px;
    .close-src {
        top: 24px;
    }
    .wrapper {
        padding: 0 40px;
    }
}


    
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {


header {
    .top-header {
        .wrapper {height: 55px;}
        .logo-center {
            width: 145px;
        }
        .right-top-header {
            position: relative;
            .ico-wishlist{
                margin: 0 24px;
            }
            .cart-header {
                .drop-cart-wrap {
                    right: 32px;
                    .cart-inner {
                        width: 368px; padding: 25px 32px;
                       > h4 {
                           font-size: 1.25rem;
                       }
                    }
                    .list-cart {
                        .box {
                            figure {width: 65px;}
                            figcaption {
                                width: calc(100% - 65px);
                                .price {
                                    font-size: 1rem;
                                    span {font-size: .625rem;}
                                }
                                .remove {
                                    img {width: 12px; display: block;}
                                }
                            }
                        }
                    }
                }
            }
            .user-header {
                .drop-user-wrap {
                    right: 32px;
                    .user-inner {
                        width: 260px;
                    }
                }
            }
        }
    }
    .main-header {
        nav {
            ul {
                li {
                    a {
                        padding: 20px 0 15px; font-size: .75rem;
                    }
                    .drop-hover-menu {
                        padding: 32px;
                        .col {
                            padding: 0 30px;
                            ul {
                                li {
                                    a {font-size: .875rem;}
                                    &.sub-2 {
                                        ul {
                                            padding-left: 30px; top: 30px;
                                        }
                                        &.act-sub-drop ul {max-height: 260px;}
                                    }
                                }
                            }
                            &.left-act {
                                padding-left: calc(33.33% + 30px);
                            }
                            .box-sub-menu a p {
                                padding: 0 16px 16px;
                            }
                        }
                    }

                }
            }
        }
    }
    &.float {
        background: rgba(31,31,31,.5);
        .main-header {
            margin-top: -50px;
        }
    }
}


footer {
    a {font-size: .875rem;}
    .nav-footer {
        .list-nav-footer {
            flex-wrap: wrap; padding-bottom: 0;
            width: calc(100% + 64px); margin-left: -32px;
            .col {
                max-width: none; width: 50%; margin-bottom: 35px;
                padding: 0 32px;
            }
        }
    }
    .middle-footer {
        .list-location-footer {
            padding-right: 190px;
            .col {
                padding-right: 30px; width: calc(50% - 20px);
                p {font-weight: 300;}
            }
        }
        .map-footer {width: 160px; height: 106px;}
    }
    .bottom-footer {
        padding: 38px 0;
    }
    .bottom-footer {
        flex-wrap: wrap;
        .col-bottom{
            width: 40%;
            &:nth-child(1) {
                width: 60%;
            }
            &.newsletter {
                width: calc(350px + 94px); margin-top: 30px; align-self: center; padding-left: 94px;
            }
            .contact-footer .contact-no {margin-right: 40px;}
        }
    }
   .nav-bottom {
       padding: 10px 0;
       ul {
           li {margin-right: 16px;}
       }
   }
}

.wrap-search-fix {
    padding-top: 40px;
    .close-src {
        width: 30px; height: 30px; top: 20px;
        &:before,&:after {width: 20px;}
    }
    .wrapper {
        padding: 0 20px;
    }
    .result-product {
       max-height: calc(100vh - 150px); display: block;max-height: calc((var(--vh, 1vh) * 100) - 150px);
        .col-result {
            width: 100%; padding-right: 10px; margin-bottom: 30px;
            &:first-child {
                width: 100%;
            }
            small {font-size: .875rem;}
            h4 {
                font-size: 1.125rem;
            }
            .ls-result {
                a {
                    figcaption {
                        h5 {margin-bottom: 0px; font-size: .875rem;}
                    }
                }
            }
        }
    }
}



    
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {

header {
    .top-header {
        .wrapper {
            height: 48px;
        }
        .logo-center {
            width: 128px;
        }
        .ico-search {
            transform: scale(.9);
        }
        .right-top-header {
            .burger-ico {
                display: block;
            }
            .ico {display: none;}
            .cart-header {
                .drop-cart-wrap {
                    right: 24px; min-height: calc(100vh - 48px); top: 48px;min-height: calc((var(--vh, 1vh) * 100) - 48px);
                    .cart-inner {
                        width: 308px; padding: 24px;
                       > h4 {
                           font-size: 1.125rem;
                       }
                    }
                    .list-cart {
                        .box {
                            figcaption {
                                .price {
                                    font-size: .875rem;
                                }
                                .stock {font-size: .875rem;}
                            }
                        }
                    }
                }
            }
            .user-header {
                .drop-user-wrap {
                    right: 24px; top: 48px;
                    .user-inner {
                        padding: 24px;
                    }
                }
            }
        }
    }
    .main-header {
        display: none; position: fixed; top: 48px; left: 0; right: 0; background:#1f1f1f; bottom: 0; padding: 20px 0;
        overflow: auto; min-height: calc(100vh - 48px); overflow: auto; min-height: calc((var(--vh, 1vh) * 100) - 48px);
        .wrapper {
            display: block;
        }
        .mobile-icon-menu {
            display: block;
        }
        nav {
            ul {
                display: block;
                li {
                    display: block; margin: 0;
                    .drop-hover-menu{
                        position: fixed;padding: 24px;
                        background: #1f1f1f; 
                        top: 48px;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: none;
                        z-index: 3;
                        .wrap-menu-sub {
                            display: block; max-height: calc(100vh - 110px); overflow: auto;max-height: calc((var(--vh, 1vh) * 100) - 110px);
                        }
                        .col {
                            width: 100%; padding-right: 0;
                            ul {
                                li {
                                    a {font-size: 1rem;}
                                    &.sub-2 {
                                        > a {
                                            padding-right: 30px;position: relative;
                                            &:after {
                                                content: ''; width: 16px; height: 18px; background: url('../images/material/icon-arr-button-2.svg') no-repeat;
                                                position: absolute; top: 0; bottom: 0; margin: auto; right: 10px; background-size: 100%;
                                                transition: all ease-out 0.25s;
                                            }
                                            &:hover{
                                                &:before {
                                                    display: none;
                                                }
                                            }
                                            &.act {
                                                &:before {
                                                    width: 30px;
                                                }
                                                &:after {
                                                    transition: all ease-out 0.25s; transform: rotate(90deg);
                                                }
                                            }

                                        }
                                        ul {
                                            position: relative; left: 0; top: 0; min-width: 0; display: none; padding-left: 15px; border-left: none;
                                            transition: none; padding: 5px 20px;
                                        }
                                    }
                                    &.back-to {
                                        display: block; padding-bottom: 10px;
                                        a {
                                            font-size: 1rem; font-weight: 500; padding-left: 32px; text-transform: uppercase; background: url('../images/material/back-menu.svg') no-repeat left center;
                                            background-size: 20px;
                                        }
                                    }
                                }
                            }
                            &.box-img {
                                padding-left: 0;display: flex; padding-top: 24px;
                                .box-sub-menu {
                                    width: calc(50% - 10px); margin-right: 20px; margin-bottom: 0;
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }

                    }
                }
            }
            > ul {
                > li {
                    > a {
                        font-size: 1rem; padding: 12px 0;
                        &:hover {
                            &:before {display: none;}
                        }
                    }
                    
                    &:hover {
                        a {
                            &:before {
                                display: none;
                            }
                        }
                    }
                    &.act {
                        > a {
                            &:before {width: 32px; height: 2px;}
                        }
                    }
                    &.have-sub {
                        > a {
                            padding-right: 30px; background: url('../images/material/ico-menu-sub.svg') no-repeat right 5px center;
                            background-size: 14px;
                        }
                    }
                }
            }
        }
    }
}


footer {
    .nav-footer {
       .list-nav-footer {
           width: calc(100% + 48px); margin-left: -24px;
           .col {
               padding: 0 24px;
           }
       }
    }
    .middle-footer {
        .list-location-footer {
            padding-right: 0;
            .col {
                margin-bottom: 24px;
            }
        }
        .map-footer {
            position: relative; width: 100%; height: 280px;
        }
    }
    .bottom-footer {
        padding: 24px 0; display: block;
        .col-bottom {
            width: 100%;
            &:nth-child(1) {
                display: block; width: 100%;
                h5 {font-size: 1rem; margin-bottom: 20px; margin-right: 0;}
            }
            h5 {font-size: 13.33px; font-weight: 500;}
            .contact-footer {
                display: block;
                a {font-size: 1.125rem;}
                .contact-no {margin-bottom: 10px;}
            }
            &.newsletter {
                width: 100%; max-width: 350px; padding-left: 0;
            }
        }
    }
    .nav-bottom {
        display: block;
        ul {
            display: block;margin-bottom: 10px;
            li {margin-right: 0; margin-bottom: 6px;}
        }
        .ico-visa {
            display: block;
            a {display: inline-block; margin: 0 10px 0 0;}
        }
    }
}


.wrap-search-fix {
    padding: 24px;padding-top: 35px;
    .close-src {
        width: 28px; height: 28px; right: 20px; top: 15px;
        &:before,&:after {width: 18px;}
    }
    .wrapper {
        padding: 0;
    }
}


   
}



@media all and (max-width: 639px) {

   
}



@media all and (max-width: 479px) {


header .main-header nav ul li .drop-hover-menu .col.box-img {
    display: block;
    .box-sub-menu {
        width: 100%; margin-right: 0; margin-bottom: 14px;
    }
}


footer {
    .nav-footer {
        .list-nav-footer {
            width: 100%; margin-left: 0;
            .col {
                padding-right: 0px; width: 100%; padding-left: 0;
                ul {
                    li {margin-bottom: 5px;}
                }
            }
        }
     }
    .middle-footer {
        padding: 30px 0 24px;
        .list-location-footer {
            .col {
                margin-bottom: 24px; width: 100%; padding-right: 0;
            }
        }
        .map-footer {
            height: 200px;
        }
    }
}



}