.inner-basket {
    display: flex; margin-bottom: 40px; 
    .left-basket {
        padding: 20px; display: flex;width: calc(100% - 250px);padding-top: 0;
        figure {
            width: 130px;
        }
        figcaption {
            width: calc(100% - 130px); padding-left: 30px;
            h4 {font-size: 1.5rem; font-weight: 400; margin-bottom: 10px;}
            small {font-size: .875rem; display: block; margin-bottom: 20px;}
            .stock {font-weight: 700; color: $color-primary;}
            .quantity-input {margin-bottom: 24px;}
        }
    }
    .right-basket {
        width: 250px; padding-left: 10px; text-align: right;
        .price {
            font-size: 1.5rem; color: #000; font-weight: 700; position: relative; margin-bottom: 8px;
            span {
                color: $color-grey; font-weight: 300; display: inline-block; vertical-align: bottom; font-size: 1.125rem;
                letter-spacing: 0px;
            }
        }
        .note-product-list {
            padding-left: 30px;
            p {font-weight: 400; color: #868686; opacity: .7;}
        }
        .remove {
            display: inline-block; margin-top: 20px;
        }
    }
    &.out-stock {
        figure,
        .price,
        .note-product-list {
            opacity: 0.4;
        }
        figcaption {
            * {opacity: .4;}
            .stock {opacity: 1;}
        }
    }
}
.listing-basket {
    padding: 20px 0;
    .inner-basket {
        padding: 35px; margin-bottom: 0; border-bottom: 1px solid #C4C4C4;
        .left-basket {
            width: calc(100% - 220px);
            figcaption {
                padding-left: 50px;
                h4 {font-size: 1.25rem;}
            }
        }
        .right-basket {
            width: 220px;
            .price {
                font-size: 1.25rem;
                span {font-size: .875rem;}
            }
            .note-product-list {
                p {font-weight: 400; color: #868686; opacity: .7; font-size: .75rem;}
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
}


@media all and (max-width: 1340px) {
    

}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {

    .listing-basket {
        .inner-basket {
            padding: 35px 24px;
            .right-basket {
                width: 170px; padding-left: 0;
                .price {
                    font-size: 1.125rem;
                    span {font-size: .625rem;}
                }
                .note-product-list {
                    padding-left: 15px;
                    p {font-size: .625rem; line-height: 1.4; margin-bottom: 6px;}
                }
            }
            .left-basket {
                width: calc(100% - 170px); padding: 0 12px;
                figure {
                    width: 110px;
                }
                figcaption {
                    padding-left: 24px; width: calc(100% - 110px);
                    h4 {font-size: 1.125rem;}

                    .time-available {font-size: .75rem;}
                }
            }
        }
    }

    
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
    .inner-basket {
        .left-basket {
            width: calc(100% - 186px);
            figcaption {
                h4 {
                    font-size: 1.375rem;
                }
            }
        }
        .right-basket {
            width: 186px;
            .price {
                font-size: 1.375rem;
                span {font-size: .875rem; display: inline-block; vertical-align: top;}
            }
            .note-product-list {
                p {line-height: 1.2; font-size: .625rem;}
            }
        }
    }

    .listing-basket {
        .inner-basket {
            padding: 30px 24px;
            .left-basket {
                width: calc(100% - 130px); padding-left: 0;
                figure {
                    width: 88px;
                }
                figcaption {
                    width: calc(100% - 88px); padding-left: 16px;
                    h4 {font-size: 1rem;}
                    small {font-size: .75rem;}
                }
            }
            .right-basket {
                width: 130px;
                .price {
                    font-size: 1rem;
                }
                .remove {
                    img {width: 12px;}
                }
            }
        }
    }
    
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
    
   
    
}



@media all and (max-width: 540px) {

    .listing-basket {
        .inner-basket {
            flex-direction: column; position: relative;
            .left-basket {
                width: 100%; padding: 0;
                figure {
                    width: 80px;
                }
                figcaption {
                    width: calc(100% - 80px);
                    .time-available.with-date {
                        margin-left: -98px;
                    }
                }
            }
            .right-basket {
                width: 100%; text-align: left; padding-bottom: 40px; position: relative;
                .note-product-list{
                    padding-left: 0;
                }
                .remove {
                    position: absolute; right: 0; bottom: 0;
                }
            }
        }
    }
    
   
}



@media all and (max-width: 350px) {

}


