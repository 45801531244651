/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/

/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";
@import "../milligram/color";

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../component/arrow-dots-slick";
@import "../pages/general";

/* header
----------------------------------------------------------------------------------------------*/
.homeSlider {
    .slider {
        .slide {
            position: relative;
            figure {
                position: relative;
                &:after {
                    content: ''; position: absolute; top: 0; bottom: 0; left: 0; right: 0;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 10.09%, rgba(52, 52, 52, 0.37) 24.87%, rgba(0, 0, 0, 0) 36.97%);
                    display: block;
                }
                img {display: block;}
            }
            .caption {
                position: absolute; bottom: 70px; left: 8%; right: 8%; color: $color-initial;
                h5 {
                    margin: 0 0 15px;
                    font-weight: 400;
                    font-style: italic;
                }
                h2,h1 {margin-bottom: 20px;}
            }
        }
    }
}
.homeSlider {
	.caption {
		h2,h1 {
			font-size: 2.875rem;
			line-height: 1.2;
			letter-spacing: 3px;
		}
	}
}
.highlight-product {
    padding: 48px 40px 10px; position: relative;
    .list-box {
        flex-wrap: wrap;
        .column {margin-bottom: 30px;}
        .box {
            position: relative; height: 100%;
            figure {
                height: 100%;
                img {display: block; height: 100%; width: 100%; object-fit: cover;}
            }
            figcaption {
                position: absolute; left: 32px; top: auto; bottom: 32px; right: 32px; color: $color-initial;
                h5 {margin-bottom: 8px;}
                .title-box {
                    font-size: 2rem; font-weight: 300; margin-bottom: 20px;
                }
            }
            &.big {
                figcaption {
                    text-align: center; max-width: 315px; left: 0; right: 0; margin: auto; bottom: 50px;
                    .price-box {
                        color: $color-primary; font-size: 2rem; font-weight: 700; margin-bottom: 40px;
                        small {font-size: .75rem; font-weight: 300; color: $color-initial;}
                    }
                }
            }
        }
        .vertical-box {
            display: flex;
            align-content: space-between;
            flex-direction: column;
            height: calc(100% + 30px);
            margin: -15px 0 0;
            .box {
                padding: 15px 0;
                figcaption {
                    bottom: 47px;
                }
            }
        }
    }
}

.best-selling {
    background: #000; padding: 55px 0 80px;
    .wrapper {
        max-width: 1170px;
    }
    .title-section {color: $color-initial; text-align: center; margin-bottom: 50px;}
    .list-best-selling {
        .column {
            text-align: center;
            figure {
                max-width: 200px; display: block; margin: auto;
                height: 175px;
                overflow: hidden;
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
            .desc-product {
                color: $color-initial;
                h5 {margin-bottom: 8px;}
                .title-product {
                    font-size: 2rem; line-height: 1.2; font-weight: 300; margin-bottom: 16px;
                } 
                p {margin: 0;}
            }
        }
    }
}




@media all and (max-width: 1480px) {
    .homeSlider .caption h2,.homeSlider .caption h1 {
        font-size: 2.4rem
    }
}
/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {

.homeSlider {
    .slider {
        .slide {
            height: 100%; position: relative;
            .caption {
                left: 40px; bottom: 32px;
                h5 {margin-bottom: 10px;}
            }
        }
    }
}
.homeSlider {
	.caption {
		h2,h1 {font-size: 1.75rem;}
	}
}
.highlight-product {
    padding: 35px 40px 10px;
    .list-box {
        .box {
            figcaption {
                bottom: 24px;
                .title-box {font-size: 1.75rem; line-height: 1.2;}
            }
            &.big {
                figcaption {
                    bottom: 35px;
                    .price-box {font-size: 1.75rem;}
                }
            }
        }
        .vertical-box {
            .box {
                figcaption {
                    bottom: 32px;
                }
            }
        }
    }
}

.best-selling {
    padding: 56px 0 60px;
    .wrapper {
        max-width: 894px;
    }
    .list-best-selling {
        .column {
            figure {
                max-width: 150px;
            }
            .desc-product {
                padding: 0 20px;
                .title-product {font-size: 1.7rem;}
            }
        }
    }
}


    
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

.homeSlider {
    .slider {
        .slide {
            .caption {
                left: 32px;
                h5 {margin-bottom: 5px;}
            }
        }
    }
}
.homeSlider {
	.caption {
		h2,h1 {font-size: 1.5rem;}
	}
}

.highlight-product {
    padding: 20px 32px;
    .list-box {
        .column {margin-bottom: 20px;}
        .vertical-box {
            height: calc(100% + 20px); margin: -10px 0;
            .box {padding: 10px 0;}
        }
        .box {
            figcaption {
                bottom: 16px; left: 26px;
                .title-box {font-size: 1.5rem; line-height: 1.2;}
            }
            &.big {
                figcaption {
                    bottom: 24px;
                    max-width: 210px;
                    .price-box {font-size: 1.5rem;}
                }
            }
        }
    }
}

.best-selling {
    .wrapper {
        max-width: 678px;
    }
    .list-best-selling {
        .column {
            figure {
                max-width: 130px; margin-bottom: 20px;
                height: 160px;
            }
            .desc-product {
                padding: 0 10px;
                .title-product {
                    font-size: 1.5rem;
                }
                p {font-weight: 300;}
            }
        }
    }
}


    
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {


.homeSlider {
    .slider {
        .slide {
            .caption {
                left: 24px;
            }
        }
    }
}
.homeSlider {
	.caption {
		h2,h1 {font-size: 1.375rem;}
	}
}

.highlight-product {
    padding: 18px 24px 5px;
    .list-box {
        .box {
            &.big  {
                figcaption {
                    max-width: 190px;
                    .price-box {
                        font-size: 1.375rem; margin-bottom: 15px;
                    }
                }
            }
            figcaption {
                h5 {line-height: 1.3;}
                .title-box {
                    font-size: 1.375rem;
                }
            }
        }
    }
}

.best-selling {
    padding: 40px 0;
    .title-section {margin-bottom: 35px;}
    .wrapper {
        max-width: none; margin: 0 24px;
    }
    .list-best-selling {
        .column {
            figure {
                height: 140px;
            }
            .desc-product {
                .title-product {font-size: 1.375rem;}
            }
        }
    }
}




}



@media all and (max-width: 639px) {




.highlight-product {
    .list-box  {
        .column {
            &.column-37,
            &.column-63 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .box {
            &.big {
                figure {
                    max-height: 600px;
                    img {object-fit: cover; object-position: 50% 25%;}
                }
                figcaption {
                    max-width: 240px;
                }
            }
            figure {
                img {
                    width: 100%; height: 100%; object-fit: cover; max-width: none;
                }
            }
            figcaption {
                left: 20px; right: 20px;
            }
        }
    }
}


.best-selling {
    .list-best-selling {
        flex-direction: column;
        .column {
            flex: 0 0 100%; max-width: 100%; margin-bottom: 30px;
            .desc-product {
                padding: 0 25px;
            }
        }
    }
}



   
}



@media all and (max-width: 440px) {

    .highlight-product {
        .list-box {
            .box {
                figure {height: 220px;}
                &.big {
                    figure {
                        height: auto;
                    }
                }
            }
            .column-37 {
                .box {
                    figure {height: auto;}
                }
            }
        }
    }

}