@import "../component/box-basket";
.popup {
	z-index: 8888;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    align-items: center;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .close-popup {
    	position: absolute; width: 23px; height: 23px; top: 20px; right: 20px;  
    	background: url('../images/material/close.png') no-repeat center center; cursor: pointer;
    	transition: all .3s ease-out; z-index: 6;
    	&:hover {
    		transform: rotate(180deg);
    	}
		&.inner {
			width: 24px; height: 24px; background: url('../images/material/icon-close-popup.svg') no-repeat;
		}
    }
    .wrap-button {
    	.button {margin-right: 15px;} 
    }
	.inner-popup{
		width: 570px;		 
		margin: auto;
		position: relative; 		
		box-sizing: border-box; 		
		z-index: 3;
		padding: 30px 0; 
	}
	.content-popup {
		display: flex;	
		background: #fff;
		padding:0;  
		flex-direction: column;   
		border-radius: 0px; position: relative;	 
		h3 {font-size: 1.5rem; font-weight: 500; color:$color-initial; letter-spacing: 5px;}  

		/*&::-webkit-scrollbar {
			width: 7px;
		}		
		&::-webkit-scrollbar-track {
			background: #f1f1f1; 
		}	
		&::-webkit-scrollbar-thumb {
			background: #888; 
		}		
		&::-webkit-scrollbar-thumb:hover {
			background: #555; 
		}*/  	
		
		.video {
			top: 0; left: 0; 
	        position: relative;
	        width: 100%;
	        height: 0;
	        padding-bottom: 56.25%;
			iframe {
				width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;					
			}
		}
		.popupSlider,.banner-pop {
			.slider {
				background: #000; 
				.slick-slide {}
			}
			figure {width: 100%;}
			figcaption {
				padding: 50px 100px 75px; 
				background: linear-gradient(180deg, #2E323D 0%, #000000 100%);
				h5 {
					color: $color-primary; font-weight: 500; margin-bottom: 10px;
					letter-spacing: 6px; 
				}
				h6 {
					color: $color-primary; font-weight: 300; margin-bottom: 10px;
					position: relative;
					padding: 5px 0 0 10px;
    				font-style: italic;
					&:before {
						position:absolute; width:87px; height: 22px; content: ""; 
						background: url('../images/material/line-title.png') no-repeat 0 0; 
						top: 0; left: 0;  	
					}
				} 
				p {margin: 0; font-size: 0.813rem; font-weight: 400;}  
			}
			.slick-arrow {
				position: absolute; width: 56px; height: 56px; top: 50%; transform: translateY(-50%);
				border:0; font-size: 0; z-index: 5; padding: 0; margin: 100px 0 0 0;    
				&.slick-prev {
					background: url('../images/material/arrow-prev.svg') no-repeat 0 0;
					left: 20px;
				}
				&.slick-next {
					background: url('../images/material/arrow-next.svg') no-repeat 0 0;
					right: 20px;  
				}
				&.slick-disabled {
					opacity: 0; cursor: default; 
				}
			}
		}
		.banner-pop figcaption {padding-bottom: 60px 50px;}
	}	
	.overlay-popup {
		margin: auto;
		z-index: 2;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		cursor: pointer; 
	}	
	&.popup-video {
		.close-popup {
			top: 0; right: -28px; 
		}
		.inner-popup {
			width: 670px; 
		}
	}
	&.basket {
		.inner-popup {
			width: 820px;
			.content-popup {
				padding: 30px;
			}
			.note-success {
				padding-left: 60px; position: relative; padding-right: 50px; margin-bottom: 30px;
				&:before {
					width: 44px; height: 44px; content: '';position: absolute; top: 0; left: 0; 
					background: #40A93D url('../images/material/ceklis-2.svg') no-repeat center;
					background-size: 20px;
				}
				h5 {margin: 0; color: #40A93D;}
				p {font-size: .875rem; color: $color-grey;}
			}
			.button-pop-wrap {
				position: relative;
				.button {
					display: block; margin: 0 auto 10px; max-width: 220px;
					&.ico {
						&:hover {
							i {background: url('../images/material/icon-button-cart.svg') no-repeat center !important;}
						}
					}
				}
				.continue {
					position: absolute;left: 0; top: 18px; padding-left: 20px;color:$color-grey; display: inline-block; font-size: .875rem; line-height: 1.7;
					&:after,&:before {
						content: '';width: 10px; height: 2px; background: $color-primary;transform: rotate(45deg);
						position: absolute;left: 0; top: 8px; bottom: auto;
					}
					&:after {transform: rotate(-45deg); bottom: 8px; top: auto;}
					&:hover {
						color: $color-primary;
					}
				}
			}
			.maching-product {
				padding-top: 30px; border-top: 1px solid $color-grey; margin-top: 20px;
				> h3 {font-weight: 700; color: #000; text-align: center;}
				.slider-matching {
					display: flex; width: calc(100% + 20px); margin-left: -10px; padding: 0 30px;
					&.slick-slider {
						display: block;
					}
					.slick-slide {
						.slide {max-width: none;}
					}
					.slide {
						padding: 0 10px; flex: 0 0 33.33%; max-width: 33.33%;
						a {
							display: block; padding: 20px; color: $color-secondary;
							figure {width: 135px; margin: auto;}
							.text-desc {
								text-align: center; padding-top: 15px;
								p {margin: 0 0 4px;}
								h3 {font-weight: 300; color: $color-secondary;margin: 0 0 4px;}
								.desc {font-size: .875rem;line-height: 1.4;}
							}
						}
					}
				}
			}
		}
	}
	&.notif-pop {
		.inner-popup {
			width: 570px;
		}
		.content-popup {
			padding: 40px; text-align: center;
		}
		.wrap-notif-pop {
			figure {
				width: 100px; margin: 0 auto 20px;
				img {width: 100%;}
			}
			p {max-width: 400px; margin: 0 auto 30px;}
			&.ico-success {
				figure {
					opacity: .7;
				}
			}
		}
	}
	&.tracking-pop {
		.inner-popup {
			width: 500px;
		}
		.content-popup {
			text-align: left; padding: 24px;
			.tracking-wrap {
				h4 {font-weight: 700; color: $color-grey; padding-bottom: 15px; margin-bottom: 20px; border-bottom: 1px solid rgba(134, 134, 134, .5)}
			}
			.list-tracking {
				padding-bottom: 20px;
				ul {
					margin: 0; position: relative;
					&:before {
						content: ''; width: 1px; background: rgba(134, 134, 134, .5); top: 20px; bottom: 20px; left: 10px;
						position: absolute; display: block; 
					}
					li {
						padding-left: 30px; position: relative; font-size: .75rem; margin-bottom: 20px;
						&:before {
							width: 12px; height: 12px; content: ''; background: $color-grey; position: absolute;
							left: 4px; top: 13px; bottom: auto; margin: auto; border-radius: 50%;z-index: 4;
						}
						.date {
							color: $color-grey; font-weight: 400; display: block; margin-bottom: 2px;
						}
						p {margin: 0;}
						a {text-decoration: underline;}
						&.pay {
							&:before {background: $color-primary;}
						}
						&:last-child {
							margin-bottom: 0;
							&:after {
								height: 50%; width: 4px; background: $color-initial; content: '';
								position: absolute; left: 8px; bottom: 0; top: auto; z-index: 3;
							}
						}
					}
				}
			}
		}
	}
	&.invoice-pop {
		.inner-popup {
			width: 880px;
		}
		.content-popup {
			padding: 40px 40px 20px;
		}
	}
	&.showroom-pop {
		.inner-popup {
			width: 940px;
		}
		.content-popup {
			padding: 50px; padding-bottom: 20px;
			.service-form {
				form {
					margin: 0;
				}
			}
			.showroom-wrap {
				h4 {color: $color-primary; font-size: 2.125rem;}
				.label {margin-bottom: 6px;}
			}
		}
	}
	&.address {
		.inner-popup {
			width: 880px;
		}
		.content-popup {
			padding: 40px;
		}
		.wrap-address-pop {
			.form {
				width: 450px;
				margin: auto;
			}
		}
	}
	&.form-pop {
		.inner-popup {
			width: 500px;
		}
		.content-popup {
			padding: 0 50px 30px;
		}
		.title-pop {
			padding: 14px 30px;
			margin: 0 -50px 40px;
			background: $color-secondary;
			h3 {
				margin: 0;
				font-size: 1rem;
				letter-spacing: normal !important;
			}
			.close-popup.inner {
				background: url('../images/material/close.png') no-repeat center;
				top: 11px;
			}
		}
		.box-form {
			label {
				font-size: .875rem;
				font-weight: 500;
				margin-bottom: 0;
				sup {
					color: $color-primary;
				}
			}
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				border: none;
				border-bottom: 1px solid $color-secondary;
				height: 36px;
				line-height: 36px;
				border-radius: 0;
				padding: 0 30px;
				padding-left: 0;
			}
			.column {
				margin-bottom: 20px;
				.button {
					width: 100%;
				}
				&.error {
					input[type="text"],
					input[type="email"],
					input[type="password"] {
						border-color: red;
					}
					.note-error {
						display: block;
					}
				}
				.note-error {
					color: red;
					font-size: .725rem;
					display: none;
					margin-top: 3px;
				}
				&.wrap-bottom {
					margin-top: 10px;
					margin-bottom: 10px;
				}
				.checkbox-custom {
					font-size: 1rem;
					font-weight: normal;
					line-height: 26px;
					margin-bottom: 10px;
					a {
						color: $color-secondary;
						text-decoration: underline;
						position: relative;
						z-index: 3;
					}
				}
			}
			.forgot-password-trigger,
			.back-login {
				display: inline-block;
				font-size: .875rem;
				color: $color-grey;
				text-decoration: underline;
				margin-top: 15px;
			}
		}
	}
}
.wrap-invoice {
	h4 {color: $color-grey;}
	.top-invoice {
		.head {
			display: flex; justify-content: space-between; align-items: center; margin-bottom: 40px;
			img {width: 140px;}
			h3 {font-size: 2rem; color: #000; letter-spacing: normal; font-weight: 700;margin: 0;}
		}
		.info {
			display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px;
			font-weight: 400;
			strong {color: $color-primary;}
		}
		
		.two-col {
			display: flex; justify-content: space-between;
			.col {
				width: 50%; padding-right: 40px;
				label {font-weight: 700; color: #000;}
				p {margin: 0; line-height: 1.5;}
				table {
					tr {
						td {
							padding: 4px 0; font-weight: 500; border: none;
							span {font-weight: 400; display: block; line-height: 1.4; padding-left: 10px;}
							&:first-child {width: 120px;}
							strong {color: $color-primary;}
						}
					}
				}
			}
		}
	}
}
.detail-product-invoice {
	table {
		thead {
			tr {
				th {
					text-align: center;
					&:first-child {text-align: left;}
				}
			}
		}
		tr {
			td {vertical-align: top;text-align: center;}
			td,th {border-bottom: 1px solid rgba(134, 134, 134, .5);}
		}
		tfoot {
			td{
				text-align: right; border: none; padding: 6px;
				&:last-child {text-align: right; padding-right: 20px;}
				&:first-child {padding-right: 60px;}
			}
			.total {
				td {
					background: #EAEAEA; padding: 10px;
					&:last-child {text-align: right; padding-right: 20px; color: $color-primary;}
					&:first-child {padding-right: 60px;}
				}
			}
		}
	}
	.product {
		display: flex; align-content: space-between; text-align: left;
		figure {
			width: 146px; padding: 23px; border: 1px solid rgba(134, 134, 134, .5);
			position: relative;
			img {z-index: 2; position: relative;}
			&:after {
				background: rgba(134, 134, 134, 0.32);
				bottom: 0;
				content: "";
				height: 40%;
				left: 0;
				position: absolute;
				right: 0;
				width: 100%;
				z-index: 1;
			}
		}
		figcaption {
			padding-left: 24px; max-width: 160px;
			h6 {font-size: .75rem; color: #000;margin-bottom: 6px;}
			p {margin: 0 0 20px; font-weight: 300;}
			small {font-size: .75rem; font-weight: 300; margin-bottom: 3px; display: block;}
			.color {
				line-height: 1.2; position: relative;
				span {
					display: block; font-size: .75rem;
					i {width: 18px; height: 18px; display: inline-block; border-radius: 50%; vertical-align: middle; margin-right: 3px;}
				}
			}
		}
	}
	.center {
		text-align: center;
		.button {min-width: 200px;}
	}
}



/* media desktop */

@media (max-width: 1620px) {

	
.popup {
	.content-popup {
		.popupSlider,.banner-pop {
			figcaption {padding: 50px 85px 70px 85px;}
			.slick-arrow {
				width: 44px; height: 44px;
				&.slick-prev {background-size: 100%;}
				&.slick-next {background-size: 100%;}
			}
		}
		.banner-pop figcaption {padding: 40px;}
	}
}

	
}
	
/* end media */


@media all and (max-width: 1340px) {
    

}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {

	.popup {
		&.basket {
			.inner-popup {
				.note-success {
					padding-left: 44px;
					h5 {line-height: 1;}
					&:before {width: 32px; height: 32px; background-size: 14px;}
					p {font-size: .75rem;}
				}
			}
		}
		&.form-pop .box-form .column .checkbox-custom {
			line-height: 20px;
		}
	}
    
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

	.popup {
		&.basket {
			.inner-popup {
				width: 682px;
				.inner-basket {margin-bottom: 30px;}
				.button-pop-wrap {
					position: relative; padding-left: 180px; display: flex;
					.continue {
						font-size: .75rem; line-height: 1.5; padding-left: 15px; top: 8px;
						&:before {top: 6px; width: 8px;}
						&:after {bottom: 6px; width: 8px;}
					}
					.button {
						margin: 0; min-width: 200px; max-width: none; margin-right: 10px;
					}
				}
				.maching-product {
					.slider-matching {
						.slide {
							a {
								.text-desc {
									h3 {font-size: 1.375rem;}
								}
							}
						}
					}
				}
			}
		}
		&.invoice-pop {
			.inner-popup {
				width: 650px;
				.content-popup {
					padding: 24px 24px 20px;
					hr {
						margin: 20px 0;
					}
				}
			}
		}
		&.showroom-pop {
			.inner-popup {
				width: 700px;
			}
			.content-popup {
				padding: 40px; padding-bottom: 20px;
				.showroom-wrap {
					h4 {font-size: 1.75rem;}
				}
			}
		}
		&.address {
			.inner-popup {
				width: 650px;
			}
		}
	}
	.wrap-invoice {
		h4 {
			font-size: 1.125rem;
		}
		.top-invoice {
			.head {
				margin-bottom: 25px;
				img {width: 100px;}
				h3 {font-size: 1.75rem;}
			}
			.info {
				span,label {font-size: .875rem;}
			}
			.two-col {
				.col {
					table {
						tr {
							td {font-size: .875rem;}
						}
					}
					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
	}
	.detail-product-invoice {
		.product {
			align-items: flex-start;
			figure {
				width: 100px; padding: 15px;
			}
			figcaption {
				padding-left: 20px;
			}
		}
		table {
			tr {
				td,th{font-size: .875rem; padding: 10px 5px;}
			}
			tfoot {
				tr:first-child {
					td {padding-top: 15px;}
				}
				td {
					padding: 4px 0;
					&:last-child {
						padding-right: 10px;
					}
				}
			}
		}
	}
    
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.popup {
		&.basket {
			.inner-popup {
				width: calc(100vw - 48px);
				.note-success {
					padding-left: 32px;
					&:before {width: 24px; height: 24px; background-size: 10px;}
					p {line-height: 1.2; margin-top: 5px;}
				}
				.maching-product {
					> h3 {font-size: 1.125rem; letter-spacing: 2px;}
					.slider-matching {
						.slide {
							a {
								.text-desc {
									h3 {font-size: 1.25rem;}
								}
							}
						}
					}
				}
				.inner-basket {
					display: block;
					.left-basket {
						width: 100%;
						figcaption {
							h4 {font-size: 1.125rem;}
							small {margin-bottom: 12px;}
							p {margin-bottom: 16px;}
						}
					}
					.right-basket {
						width: 100%; text-align: left; padding-left: 0;
						.note-product-list {
							padding-left: 0;
						}
						.price {
							font-size: 1.125rem;
							span {font-size: .75rem;}
						}
					}
				}
				.button-pop-wrap {
					position: relative; padding-left: 165px; display: flex;

					.button {
						margin: 0; min-width: 0px; width: calc((100% - 10px) / 2); margin-right: 0px;
						&.ico {
							margin-right: 10px;
						}
					}
				}
			}
		}
		&.tracking-pop {
			.content-popup {
				padding: 24px 16px ;
				.tracking-wrap {
					h4 {font-size: 1rem; padding-bottom: 10px; margin-bottom: 12px;}
				}
			}
		}
		&.invoice-pop {
			.inner-popup {
				width: calc(100vw - 48px);
				.content-popup {
					padding: 24px 24px 20px;
				}
			}
		}
		&.showroom-pop {
			.inner-popup {
				width: calc(100vw - 48px);
			}
			.content-popup {
				padding: 24px; padding-bottom: 10px;
				.showroom-wrap {
					hr {margin: 15px 0;}
					h4 {font-size: 1.375rem;}
				}
			}
		}
		&.address {
			.inner-popup {
				width: calc(100vw - 48px);
			}
			.content-popup {
				padding: 30px 24px;
			}
			 .wrap-address-pop .form {
				 width: auto;
			 }
		}
	}
}



@media all and (max-width: 639px) {

	.popup {
		.inner-popup {
			width: 100vw; margin: auto;
			.content-popup {
				.popupSlider,.banner-pop {
					position: relative;
					figure {
						img {width: 100%;}
					}
					figcaption {
						padding: 40px 20px 100px;
						h6 {font-size: 0.813rem;}
						h5 {font-size: 1rem; margin-bottom: 10px;}
						h3 {font-size: 1.188rem; margin: 0 0 12px;}
					}
					.slick-arrow {
						width: 40px; height: 40px; top: auto; transform: translateY(0); z-index: 7; bottom: 30px;
						&.slick-prev {}
						&.slick-next {}
					}
				}
				.banner-pop figcaption {padding-bottom: 40px;}
			}
		}
		&.basket {
			.inner-popup {
				.content-popup {
					padding: 24px;
				}
				.button-pop-wrap {
					position: relative; padding-left: 0; display: block; padding-top: 38px;

					.button {
						width: 100%;
						&.ico {
							margin-right: 0px; margin-bottom: 10px;
						}
					}
				}
				.inner-basket {
					.left-basket {
						display: block; padding: 0;
						figure {max-width: 130px; margin: auto;}
						figcaption {
							width: 100%; padding: 20px 0;
						}
					}
				}
			}
		}
		&.tracking-pop {
			.inner-popup {
				width: calc(100% - 48px);
			}
		}
		&.showroom-pop {
			.content-popup {
				.showroom-wrap {
					.service-form {
						.row {
							.column {
								&.column-50 {
									flex: 0 0 100%;
									max-width: 100%;
								}
							}
						}
					}
				}
			}
		}
		&.form-pop {
			.inner-popup {
				width: calc(100vw - 40px);
			}
			.content-popup {
				padding: 0 30px 20px;
			}
			.title-pop {
				padding: 14px 20px;
				margin: 0 -30px 20px;
				background: $color-secondary;
				h3 {
					margin: 0;
					font-size: .875rem;
				}
				.close-popup.inner {
					background: url('../images/material/close.png') no-repeat center;
					top: 9px;
				}
			}
			.box-form {
				label {
					font-size: .75rem;
				}
				.column {
					.checkbox-custom {
						font-size: .875rem;
						line-height: 18px;
					}
				}
			}
		}
	}
	.popup.popup-video .close-popup {top: -34px; right: 10px;}
   
}



@media all and (max-width: 350px) {

}


