/* slick-dots & arrow
----------------------------------------------------------------------------------------------*/

.slick-arrow {
    position: absolute; width: 60px; height: 60px; border: none; font-size: 0; top: 0; bottom: 0; left: 45px; right: auto;
    margin: auto; border-radius: 50%; z-index: 4; background: rgba(31, 31, 31, .5) url('../images/material/icon-slide-arrow.svg') no-repeat center;
    padding: 0; transition: all ease-out 0.25s;
    &:before {display: none;}
    &.slick-next {transform: rotate(180deg);}
    &.slick-next {left: auto; right: 45px;}
    &:hover {
        transition: all ease-out 0.25s; background-color: $color-secondary;
    }
}

.homeSlider {
    .slick-dots {
        bottom: 30px;
        li {
            button {height: 4px; line-height: 4px; width: 50px;}
            &.slick-active {
                button {width: 60px;}
            }
        }
    }
}
.slider-matching {
    .slick-arrow {
        width: 44px; height:44px; background-size: 26px; left: 10px;
        &.slick-disabled {
            opacity: 0;
        }
        &.slick-next {
            right: 10px; left: auto;
        }
    }
}
.img2-slider {
    .slick-arrow {
        width: 24px; height: 20px; background: url('../images/material/arr-slide-2.svg') no-repeat center;
        border-radius: 0;
        display: none !important;
    }
    &:hover {
        .slick-arrow {
            display: inline-block !important;
        }
    }
}
.list-unduh {
    .slick-arrow {
        width: 48px; height: 48px; background-size: 26px; left: -50px;
        &.slick-next {
            left: auto; right: -50px;
        }
        &.slick-disabled {
            opacity: 0;
        }
    }
}

.slick-dots {
    position: absolute; z-index: 3; bottom: 5px; display: flex; justify-content: center; left: 0; right: 0; margin: 0;
    li {
        margin: 0 4px;
        button {
            padding: 0; height: 6px; line-height: 6px; border-radius: 0; background: $color-grey; border: none; margin: 0;
            transition: all ease-out 0.25s; width: 16px; box-shadow: none; font-size: 0;
            &:before {display: none;}
        }
        &.slick-active,&:hover {
            button {opacity: 1;transition: all ease-out 0.25s; background: $color-secondary;}
        }
        &.slick-active {
            button {width: 20px;}
        }
    }
}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {
    .slick-arrow {
        width: 40px; height: 40px; padding: 0; background-size: 24px; left: 40px; top: 42%; bottom: auto;
        &.slick-next {right: 40px;}
    }
    .slick-dots {
        bottom: 74px;margin: 0;
        li {margin: 0 4px;}
    }
    .homeSlider {
        .slick-dots {
            bottom: 20px;
            li {
                button {width: 20px;}
                &.slick-active {
                    button {
                        width: 32px;
                    }
                }
            }
        }
    }
    .slider-thumb {
        .slick-dots {
            bottom: 0;
        }
    }
    .list-unduh {
        .slick-arrow {
            width: 40px; height: 40px;left: -40px;
            &.slick-next {
                right: -40px; left: auto;
            }
        }
    }
    .slider-matching {
        .slick-arrow {
            width: 40px; height:40px; background-size: 24px;
        }
    }
    .img2-slider .slick-arrow {background-size: 20px;}
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

.slick-arrow {
    width: 34px; height: 34px; padding: 0; background-size: 20px; left: 32px; top: 36%; bottom: auto;
    &.slick-next {right: 32px;}
}

.list-unduh {
    .slick-arrow {
        width: 32px; height: 32px;left: -35px; background-size: 20px;
        &.slick-next {
            right: -35px; left: auto;
        }
    }
}

.slider-thumb .slick-dots {bottom: -18px;}

.slider-matching {
    .slick-arrow {
        width: 32px; height:32px; background-size: 20px;
    }
}

.img2-slider .slick-arrow {
    background-size: 16px;
    left: 20px;
    &.slick-next {
        right: 20px; left: auto;
    }
}



}



/* responsive mobile
----------------------------------------------------------------------------------------------*/

@media all and (max-width: 767px) {
    .slick-arrow {
        left: 20px;background-size: 16px ;
        &.slick-next {
            left: auto; right: 20px;
        }
    }

    .list-unduh {
        .slick-arrow {
            width: 26px; height: 26px;left: -22px; background-size: 16px;
            &.slick-next {
                right: -22px; left: auto;
            }
        }
    }
    .slick-dots li button {height: 4px;}
    .slider-thumb .slick-dots {bottom: -5px;}
    .slider-matching {
        .slick-arrow {
            width: 26px; height:26px; background-size: 16px; left: 0;
            &.slick-next {
                left: auto; right: 0;
            }
        }
    }
    .slide-advantage {
        .slick-dots {
            bottom: -20px;
        }
    }
}