/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/

/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";
@import "../milligram/color";
@import "../component/popup";

/* general
----------------------------------------------------------------------------------------------*/
.middle {
    padding: 118px 0 100px; position: relative; background: #ECECEC; overflow: hidden;
    &.home {
        background: $color-secondary; padding-bottom: 0;
    }
    &.no-header-bottom{
        padding-top: 60px;
        &:before {
            display: none;
        }
    }
    &.no-banner {
        &:before {
            display: none;
        }
    }
    &:before {
        height: 118px;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        background: rgba(31,31,31,1);
        display: block;
    }
    &.no-menu {
        padding-top: 60px;
        &:before {
            height: 60px;
        }
    }
}
.product-wrap,
.shopping-basket-wrap {
    padding: 0; margin-top: 20px;
}
.title-section {text-align: center;}
.note-product-list {
    p {font-size: .875rem; color: $color-grey; line-height: 1.2rem; margin: 0; font-weight: 400; margin-bottom: 10px;}
}
.time-available {
    padding-left: 30px; position: relative; display: block; background: url('../images/material/ico-time.svg') no-repeat left center;
    margin-bottom: 40px; line-height: 1.25; position: relative;
    &.green {
        background: url('../images/material/ico-time-2.svg') no-repeat left center; color: #49AD46;
    }
    &.with-date {
        padding-right: 90px;
        label {
            font-size: .875rem; font-weight: 300; position: absolute; top: 3px; width: 87px; right: 0; color: $color-grey;
            line-height: 1.4; margin: 0;
        }
    }
}
.note-promo {
    text-align: center; display: block;
    &:before {
        content: ''; display: inline-block; width: 14px; height: 14px; background: url('../images/material/ceklis.svg') no-repeat;
        margin-right: 4px;
    }
}
.choose-color {
    display: flex; margin-bottom: 6px;
    .checkbox-custom {
        width: 26px; padding-left: 0; height: 26px; margin-right: 8px; margin-bottom: 8px;
        input{
            cursor: pointer;
            &:checked {
                + i {
                    background: $color-initial url('../images/material/ceklis.svg') no-repeat center !important; transition: none;
                    background-size: 12px !important; border-color: $color-grey;
                    &:before,&:after {
                        opacity: 0;
                    }
                }
            }
        }
        i {
            transition: none;
            &:before {
                content: ''; width: 100%; height: 100%; position: absolute; border: 5px solid $color-initial; background: transparent;
                border-radius:50%;
            }
        }
    }
}
.list-box-question {
    display: flex; justify-content: center;
    .box {
        width: 440px;
        padding: 45px 30px 90px;
        background: $color-initial;
        margin: 0 12px;
        text-align: center;
        position: relative;
        .ico {margin-bottom: 20px;}
        p {margin: 0; font-weight: 500;margin-bottom: 5px; font-size: 1.125;}
        h5 {font-weight: 700;margin-bottom: 15px;}
        .arrow-link {
            margin-top: 20px; display: inline-block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 40px;
        }
    }
}
.any-question {
    padding: 80px 0 60px;
    .title-section {
        font-weight: 400; margin-bottom: 40px;
    }
}
.compare-floating {
    position: fixed; bottom: 0; z-index: 11; left: 0; right: 0; padding: 0 45px; display: none;
    .ttl {
        padding: 18px 16px; padding-left: 45px; color: $color-initial; background: $color-secondary; margin: 0;
        display: inline-block; font-weight: 300; position: relative; cursor: pointer;
        &:before {
            content: ''; width: 15px; height: 9px; background: url('../images/material/icon-drop.svg')  no-repeat;
            position: absolute;left: 20px; top: 0; bottom: 0; margin: auto;
        }
    }
    .wrap-float-caompare {
        background: $color-secondary; padding:22px 45px; margin: 0 -45px;
    }
    .name-compare {
        margin-bottom: 24px;
      a {
          color: $color-grey; margin-right: 30px;
          &.active {
              color: $color-initial;
          }
        }  
    }
    .list-- {
        display: flex; width: 100%; padding-right: 270px; position: relative;
        .box {
            width: 300px; height: 85px; border: 2px dashed $color-initial; margin-right: 24px; position: relative;
            figure {
                height: 100%; width: 100%;
                span{color: $color-initial; position: relative; display: block; transform: translateY(-50%); top: 45%; text-align: center; font-weight: 300;}
            }
            &.button-- {
                position: absolute; right: 0; width: 270px; border: none; flex-direction: column;
                text-align: center; display: flex; align-items: center; margin-right: 0;
                .button {width: 100%;}
                .del {color: $color-initial; display: block; margin-top: 10px; text-decoration: underline;}
            }
            .prod {
                background: $color-initial; display: flex; align-items: center; height: 100%;
                padding: 10px;
                img {width: 55px;}
                h5 {width: calc(100% - 55px); padding-left: 20px;margin: 0; font-size: 1rem;}
            }
            .deleted {
                width: 24px; height: 24px; position: absolute; right: 6px; top: 6px; cursor: pointer;z-index: 3; display: none;
                &:before,&:after {
                    content: ''; width: 12px; height: 2px; position: absolute; display: block; top: 0; bottom: 0; margin: auto; left: 0;
                    right: 0; transform: rotate(45deg); background: $color-grey;
                }
                &:after {transform: rotate(-45deg);}
            }
            &.act {
                border: none;
                &:hover {
                    .deleted {display: block;}
                }
            }
        }
    }
}
.title-wrap,.checkout-page {
    padding-top: 80px;
    .title-page {
        text-align: center; width: 869px; margin: 0 auto 80px;
        h1,h2 { font-size: 3rem; font-weight: 300; line-height: 1.2; }
        p {font-size: 1.5rem; font-weight: 300; line-height: 1.4; margin: 0;}
        img {display: block; margin: 30px auto;}
    }
}

.right-inner {
    width: 396px;background: $color-initial; padding: 35px;
    .dl {
        width: 100%; display: flex; justify-content: space-between; font-weight: 700; color: $color-secondary; padding: 6px 0;
        position: relative;
        small {position: absolute;display: block; font-size: .875rem; color: $color-grey; bottom: 5px;}
        label {
            font-size: 1.125rem;
            small {left: 0;}
        }
        span {
            font-size: 1.25rem; text-align: right;
            small {right: 0;}
        }
        &.total {
            padding: 10px 0 30px; border-top: 1px solid #C4C4C4; align-items: center; margin: 10px 0 30px;
            span {font-size: 2rem;}
        }

    }
    .redeem-voucher {
        label {font-size: .875rem; color: #000;font-weight: 700;margin: 0 0 10px;}
        form {
            width: 100%; position: relative;
            &::placeholder {
                color: $color-grey;
            }
            input[type="text"] {
                padding: 10px 90px 10px 16px; width: 100%; height: 50px; border-radius: 0; font-weight: 500;
                font-size: 1rem; overflow: hidden; white-space: nowrap;text-overflow: ellipsis; margin: 0;
            }
            button {
                position: absolute; padding: 0; right: 0; top: 0; bottom: 0; font-size: .875rem; margin: 0;
                width: 90px; background: transparent; color: $color-secondary; border: none; letter-spacing: 0;
                border-left: 1px solid #868686;
                &:hover {
                    color: $color-initial;
                }
            }
        }
    }
    .button {width: 100%;text-align: center;}
    .bottom-atm {
        text-align: center; margin-top: 20px;
        a {display: inline-block;}
        p {
            font-size: .75rem; color: $color-grey; margin: 10px 0 0;
            a {
                color: $color-grey;
                &:hover {color: $color-primary;}
            }
        }
    }
}

header {
    &.float {
        +div+.middle {
            .float-contact {
                top: 60px;
            }
        }
    }
}
.float-contact { background: $color-grey; left: 0; padding: 72px 50px; position: fixed; right: 0; top: 118px; z-index: 3; 
    .close { position: absolute; right: 20px; top: 20px; z-index: 11; }
    .contact-box { display: flex; flex-wrap: wrap; margin: 0 -20px;
        .item { background: $color-initial; margin: 0 20px; padding: 48px; text-align: center; width: calc(50% - 40px); 
            img { display: inline-block; margin-bottom: 40px; }
            h4 { font-weight: bold; font-size: 24px; line-height: 18px; }
            span { display: block; font-weight: 500; font-size: 24px; line-height: 18px; margin-bottom: 12px; }
            a { display: inline-block; font-weight: bold; font-size: 14px; line-height: 17px; margin-top: 20px; 
                > img{ float: left; margin-right: 8px; }
            }
        }
    }
    .any-question {
        padding: 0;
        h3 {color: $color-initial;}
    }
}
.box-search-page {
    padding: 40px 80px; width: 1000px;box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px; margin: -110px auto 60px; background: $color-initial; position: relative;z-index: 2;
    p {margin: 0; color: $color-grey;}
    form {margin: 0 0 10px;}
}

.ui-icon,
.ui-widget-content .ui-icon,
.ui-widget-header .ui-icon {
	background-image: url("../images/material/ui-icons_444444_256x240.png");
}
.ui-widget-header .ui-icon {
	background-image: url("../images/material/ui-icons_444444_256x240.png") !important;
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
	background-image: url("../images/material/ui-icons_555555_256x240.png");
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
	background-image: url("../images/material/ui-icons_ffffff_256x240.png");
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
	background-image: url("../images/material/ui-icons_777620_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
	background-image: url("../images/material/ui-icons_cc0000_256x240.png");
}
.ui-button .ui-icon {
	background-image: url("../images/material/ui-icons_777777_256x240.png");
}

.ui-datepicker td span, .ui-datepicker td a{
    text-align: center;
}
.ui-state-active, 
.ui-widget-content .ui-state-active, 
.ui-widget-header .ui-state-active, 
a.ui-button:active, 
.ui-button:active, 
.ui-button.ui-state-active:hover,
.ui-state-highlight, 
.ui-widget-content .ui-state-highlight, 
.ui-widget-header .ui-state-highlight {
    background: $color-primary;
    border-color: $color-primary;
    color: $color-initial;
}


.box-login {
    width: 540px;
    background: $color-initial;
    padding: 35px;
    margin: 0 auto;
    .button {
        width: 100%; margin-bottom: 15px;
    }
    .center {
        text-align: center;
    }
    .title-list {
        margin-bottom: 15px;
    }
    ul {
        list-style: none;
        li {
            padding-left: 30px; position: relative; margin-bottom: 6px;
            &:before {
                width: 24px; height: 24px; content: ''; left: 0; top: 0; position: absolute;
                background: url('../images/material/ceklis.svg') no-repeat center top 4px;
            }
        }
    }
}


.pagging {
    position: relative;
    padding: 35px 0 15px;
    margin-bottom: 20px;
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        li {
            margin: 0 5px;
            a,span {
                display: block;
                width: 24px;
                text-align: center;
                padding-bottom: 10px;
                position: relative;
                color: #868686;
                transition: all ease-out 0.25s;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 2px;
                    background: $color-primary;
                    display: block;
                    margin: auto;
                    width: 0;
                    transition: all ease-out 0.25s;
                }
                &.active,&:hover {
                    color: $color-primary;
                    transition: all ease-out 0.25s;
                    &:before {
                        width: 100%;
                        transition: all ease-out 0.25s;
                    }
                }
                &.active {
                    font-weight: 700;
                }
                &.next-arr,
                &.prev-arr {
                    height: 24px;
                    padding: 0;
                    background: url('../images/material/back-menu.svg') no-repeat center;
                    margin-right: 12px;
                    &:before {
                        display: none;
                    }
                }
                &.next-arr {
                    transform: rotate(180deg);
                    margin-left: 12px;
                    margin-right: 0;
                }
                &.disabled {
                    display: none;
                }
            }
            span:before {display: none;}
        }
    }
}

.ui-widget-header .ui-icon {
    background-image: url("../images/ui-icons_444444_256x240.png");
}

.have-tooltip {
    display: inline-block;
    position: relative;
    text-decoration: underline;
    cursor: pointer;
    .tooltip-data {
        text-decoration: none;
        width: 180px;
        padding: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        z-index: 4;
        left: auto;
        right: calc(100% + 10px);
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        cursor: auto;
        display: none;
        &:after {
            content: '';
            height: 1px;
            width: 1px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 8px solid #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: -8px;
            left: auto;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
        }
        .img-tooltip {
            position: relative;
            margin: 0 auto 15px;
            max-width: 130px;
            img {
                display: block;
                width: 100%;
            }
        }
        .desc-tooltip {
            h6 {
                font-size: .93rem;
                margin-bottom: 6px;
            }
            p {
                font-size: .8125rem;
                margin-bottom: 12px;
                opacity: .5;
                line-height: 1.3;
                &.intro {
                    opacity: 1;
                    margin-bottom: 8px;
                    line-height: 1.4;
                }
            }
        }
    }
    &.to-right {
        .tooltip-data {
            left: calc(100% + 10px);
            &:after {
                left: -8px;
                right: auto;
                border-left: none;
                border-right: 8px solid #fff;
            }
        }
    }
}


@media all and (max-width: 1340px) {
    

}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1279px) {


.middle {
    padding: 118px 0 60px;
}
.time-available {
    font-size: .875rem; line-height: 1.4; margin-bottom: 30px;
    &.with-date {
        padding-right: 74px;
        label {
            font-size: .75rem; width: 70px; top: 0;
        }
    }
}
.choose-color {
    .checkbox-custom {
        width: 24px;height: 24px;
        i {width: 24px;height: 24px;}
    }
}


.right-inner {
    width: 315px; padding: 24px;
    .dl {
        label,span {font-size: 1rem; line-height: 1.2; display: block;}
        &.total {
            span {font-size: 1.5rem;}
        }
        small {font-size: .75rem;}
    }
    .redeem-voucher {
        form {
            input[type="text"] {
                font-size: .875rem; height: 46px;
            }
            button {
                font-size: .75rem; width: 86px;
            }
        }
    }
}

.any-question {
    padding: 60px 0 45px;
}

.list-box-question {
    flex-wrap: wrap;
    .box {
        width: 320px; padding: 32px 50px 70px;
        margin: 0 8px 16px;
        .ico {
            img {width: 32px;}
        }
        .arrow-link {bottom: 35px;}
    }
}

.title-wrap , .checkout-page {
    padding-top: 60px;
    .title-page {
        width: 720px; margin-bottom: 40px;
        h1,h2 {
            font-size: 1.875rem;
        }
        p {font-size: 1.125rem;}
    }
}

.float-contact {
    top: 120px; padding: 45px 45px 60px;
}

.box-search-page {
    width: auto; padding: 30px 60px;
    margin: -80px auto 40px;
}
.style-src form .input input[type=text] {
    height: 40px;
}


    
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {

.middle {
    padding: 105px 0 40px;
    &:before {
        height: 105px;
    }
    &.no-menu {
        padding-top: 55px;
        &:before {
            height: 55px;
        }
    }
}

.time-available {
    margin-bottom: 25px;
    font-size: .75rem; line-height: 1.4; padding-left: 23px; background-size: 14px !important;
}

.note-product-list {
    p {font-size: .75rem;}
}
.note-promo {font-size: .875rem;}

.right-inner {
    width: 230px; padding: 20px 12px;
    .dl {
        label,span {font-size: .875rem;}
        &.total {
            padding-bottom: 20px;
            span {font-size: 1.125rem;}
        }
        small {font-size: .625rem; bottom: 7px;}
    }
    .redeem-voucher {
        label {font-size: .75rem;}
        form {
            margin-bottom: 16px;
            input[type="text"] {
                font-size: .75rem; height: 34px; padding-left: 12px; padding-right: 70px;
            }
            button {
                font-size: .625rem; width: 68px;
            }
        }
    }
    .bottom-atm {
        margin-top: 10px;
    }
}


.title-wrap , .checkout-page {
    padding-top: 50px;
    .title-page {
        width: 588px;
        h1,h2 {
            font-size: 1.5rem;
        }
        p {font-size: 1rem;}
    }
}

.float-contact {
    top: 105px; padding: 45px 35px 40px;
}

.box-search-page {
    padding: 18px 40px; margin-top: -60px;
    form {
        margin-bottom: 0 0 5px;
    }
}

.style-src form label {
    font-size: .75rem; margin: 0;
}

.compare-floating {
    .list-- {
        padding-right: 180px;
        .box.button-- {
            width: 170px;
        }
        .box {
            img {
                display: none;
            }
            .prod h5 {
                text-align: center;
                line-height: 1.2;
                padding-left: 0;
                width: 100%;
            }
            figure {
                span {
                    line-height: 1.2;
                    font-size: .825rem;
                    padding: 0 10px;
                }
            }
            
        }
    }
}


    
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {


.middle {
    padding: 48px 0 40px;
    &.no-header-bottom {
        padding-top: 48px;
    }
    &:before {
        height: 48px;
    }
    &.no-menu {
        padding-top: 48px;
        &:before {
            height: 48px;
        }
    }
}

.note-promo {font-size: .75rem;}
.time-available {
    margin-bottom: 15px;
}


.choose-color {
    .checkbox-custom {
        width: 16px;height: 16px;
        input {
            &:checked {
                + i {background-size: 8px !important;}
            }
        }
        i {
            width: 16px; height: 16px;
            &:before {border-width: 3px;}
        }
    }
}

.right-inner {
    width: 100%;
}

.any-question {
    padding: 30px 0;
    .title-section {
        margin-bottom: 30px;
    }
}


.title-wrap , .checkout-page {
    padding-top: 40px;
    .title-page {
        width: auto;
        h1,h2 {
            font-size: 1.375rem;
        }
        p {font-size: .875rem;}
    }
}

.float-contact {
    top: 48px; padding: 40px 24px 20px;
}

.box-search-page {
    padding: 16px 24px;
}

.compare-floating {
    padding: 0 25px;
    .list-- {
        .box {
            .prod {
                h5 {
                    font-size: .825rem;
                }
            }
            figure{
                span {
                    font-size: .75rem;
                }
            }
        }
    }
}

   
}



@media all and (max-width: 639px) {

    .list-box-question {
        flex-direction: column;
        .box {
            width: calc(100% - 30px); margin: 0 auto 20px;padding: 20px;
            padding-bottom: 60px;
            .ico {margin-bottom: 10px;}
            .arrow-link {
                bottom: 25px;
            }
        }
    }
    .box-login {
        width: auto;
        padding: 30px 25px;
        h4 {
            font-size: 1.125rem;
        }
    }
    .compare-floating {
        .ttl {
            padding: 10px 16px;
            font-size: .75rem;
            padding-left: 40px;
        }
        .name-compare {
            margin-bottom: 16px;
            a {
                font-size: .75rem;
            }
        }
        .list-- {
            flex-wrap: wrap;
            .box {
                width: 100%;
                height: auto;
                padding: 10px;
                margin-bottom: 12px;
                &.act {
                    padding: 0;
                    .prod {
                        padding: 10px;
                    }
                    .deleted {
                        display: block;
                    }
                }
                
                .deleted {
                    right: -28px;

                    &:after,&:before {
                        background: #fff;
                    }
                }
                &:nth-child(3) {
                    margin-bottom: 0;
                }
            }
        }
    }
}



@media all and (max-width: 350px) {

}